<template>
  <div class="stats text-white pb-20" v-if="stats">
    <Header />
    <div class="flex w-full justify-between pt-4 px-4 items-center">
      <p
        class="font-ZuumeSemiBold text-nColorYellow text-5xl text-shadow-black-right"
      >
        {{ stats.game_name }}
      </p>
    </div>
    <div class="flex gap-4 px-4">
      <p
        class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
      >
        {{ formattedGamePlays }}.
      </p>
      <p
        class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
      >
        {{ formattedLeaderboardCount }}.
      </p>
    </div>
    <div v-if="stats.leaderboard" class="w-full px-4 pt-2">
      <div v-for="(result, index) in stats.leaderboard.results" :key="result">
        <div
          class="flex justify-between items-center gap-2 text-white rounded-xl p-2"
        >
          <div class="w-10 text-center">
            <div class="text-nColorGreen font-bold font-InterSemiBold text-xl">
              <p>{{ index + 1 }}</p>
            </div>
          </div>
          <div class="w-3/6">
            <p class="font-InterSemiBold text-lg">{{ result.player }}</p>
            <p class="font-InterRegular text-xs text-nColorSubTextGray">
              {{ result.mobile }}
            </p>
          </div>
          <div class="w-3/6">
            <div
              class="bg-lColorBGGray p-0.5 rounded-xl border-4 border-lColorBGGray"
            >
              <div class="flex gap-2">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                    alt="Score"
                    width="20"
                    height="19"
                  />
                </div>
                <p class="font-InterSemiBold text-sm">
                  {{ result.highest_score.score }}
                </p>
              </div>
              <div class="flex gap-2 mt-2" v-if="result.highest_score.time">
                <div class="pl-1">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                    alt="Time Taken"
                    width="15"
                    height="14"
                  />
                </div>
                <p class="font-InterSemiBold text-sm">
                  {{ timeTaken(result.highest_score.time) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/Elements/Header.vue";

export default {
  name: "Stats",
  components: { Header },
  data() {
    return {
      stats: null,
    };
  },
  computed: {
    formattedGamePlays() {
      if (this.stats.total_game_plays >= 1000) {
        return (this.stats.total_game_plays / 1000).toFixed(2) + "k Game Plays";
      } else {
        return this.stats.total_gameplays + " Game Plays";
      }
    },
    formattedLeaderboardCount() {
      if (this.stats.players >= 1000) {
        return (this.stats.players / 1000).toFixed(2) + "k on Leaderboard";
      } else {
        return this.stats.players + " on Leaderboard";
      }
    },
  },
  created() {
    ApiService.get(apiResource.getScheduleDetails, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
    }).then((data) => {
      this.stats = data.data.data;
    });
  },
  mounted() {},
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
  },
};
</script>

<style scoped>
.stats {
  min-height: 100dvh;
}
</style>
